@import 'mine.css/dist/mine.css';

:root {
  --margin: 1em;
}

body {
  min-height: 100vh;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.9em;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  text-transform: uppercase;
}

a {
  color: unset;
  text-decoration: none;
  transition: 0.3s;
}

a:hover {
  background: black;
  color: white;
}

img {
  max-width:100%;
}

::selection {
  background: black; /* WebKit/Blink Browsers */
  color: white;
}

.top-nav {
  position: sticky;
  top: 0px;
  right: var(--margin);
  padding-top: var(--margin);
  align-self: flex-end;
}

.feed-container {
  margin: var(--margin);
}

.moodboard {
  height: 100vh;
  max-width: 100vh;
  border-bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center
}

.moodboard a:hover {
  background: unset;
  color: unset;
}

@supports(padding: max(0px)) {
  body {
      padding-top: env(safe-area-inset-top);
      padding-left: env(safe-area-inset-left);
      padding-right: env(safe-area-inset-right);
      padding-bottom: env(safe-area-inset-bottom);
  }
}
